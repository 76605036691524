// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $('.about-content-wrapper').matchHeight();
    $('.blogTitle').matchHeight();
    $('.blogDescription').matchHeight();
    $('.pf-recent-related-product-item.product-match-height').matchHeight();
});
$(function () {
    $(window).on('resize', SetNavDropdownMaxHeight);
    $(document).on('click', '.menu-button', SetNavDropdownMaxHeight);
    $(document).on('click', '.nav-link.nav-dropdown', SetNavDropdownMaxHeight);
    $(document).on('click', '.bag-opener.w-nav-link', SetNavDropdownMaxHeight);
    $('#bag-opener').click(function () {
        SetNavDropdownMaxHeight();
    });
});

function SetNavDropdownMaxHeight() {
    if ($(window).width() < 768) {
        $('.w-nav-overlay').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.mega-list').css('max-height', '100%');
    } else {
        $('.mega-list').css('max-height', ($(window).height() - $('header.header').outerHeight()) + 'px');
        $('.topheader').css('max-height', '400px');
        $('.topheader').css('overflow', 'auto');
        $('.w-nav-overlay').css('max-height', '100%');
    }
}

//To be turned on if useful: 


////// compact header when opening and closing the expanded search functionality. 
//$('.search-opener').click(function () {
//    $(window).scrollTop(0);
//    $('#search-div-block-compact').show();
//    $('.logo-section').hide();
//});
//$('.search-close').click(function () {
//    if (document.body.scrollTop < 50 || document.documentElement.scrollTop < 50) {
//        $('#search-div-block-compact').hide();
//        $('.logo-section').show();
//    }
//});


///// Hide promotional strip when using mobile nav. 
//$('.hamburger-menu-button').click(function () {
//    console.log($(this).is('.w--open'));
//    if ($(this).is('.w--open')) {
//        $('.pf-promotion-container').hide()
//    } else {
//        $('.pf-promotion-container').show()
//    }
//});

////// CoachHouse's compact header functionality
//window.onscroll = function () { scrollFunction() };
//function scrollFunction() {
//    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
//        $('#search-div-block-compact').show();
//        $('.logo-section').hide();
//    } else {
//        if (!$('.search-section').is(':visible')) {
//            $('#search-div-block-compact').hide();
//            $('.logo-section').show();
//        }
//    }
//}


/////// additional mac/ios specific styling required. add the class mac-os to the required element
//if (navigator.userAgent.indexOf('Mac') > 0) {
//    $('.pf-new-product-tag').each(function () {
//        $(this).find('span').addClass('mac-os')
//    });
//    $('.pf-sale-product-tag').each(function () {
//        $(this).find('span').addClass('mac-os')
//    });
//}